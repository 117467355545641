import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Tag from '@/components/common/Tag'

const NewsCard1 = props => {
  const { data, ...others } = props
  return (
    <Root
      {...others}
      to={data.slug}
    >
      <Head>
        <Date
          variant="caption2"
          component="time"
          theme={props.theme}
          dateTime={`${data.date.fullYear}-${data.date.month}-${data.date.date}`}>
          {`${data.date.fullYear}.${('00' + data.date.month).slice(-2)}.${('00' + data.date.date).slice(-2)}`}
        </Date>
        <Tags>
          {data.tags.map((tag, index) => (
            <Tags_Item key={index}>
              <Tag color="primary">{tag}</Tag>
            </Tags_Item>
          ))}
        </Tags>
      </Head>
      <Main>
        <Title variant="body2" to={data.slug} theme={props.theme}>{data.title}</Title>
      </Main>
    </Root>
  )
}

export default ThemeConsumer(NewsCard1)

const Root = styled(Link)`
  display: block;
  padding: 18px;
  border-top: ${props => props.index === 0
    ? `1px solid ${props.theme.line.base}`
    : 'none'
  };
  border-bottom: 1px solid ${props => props.theme.line.base};
  @media (hover: hover) {
    transition: background-color ease 0.2s;
    &:hover {
      background-color: ${props => props.theme.background.hover.base};
    }
  }
  @media ${mq.and(mobile)} {
    display: block;
    padding: 16px 0;
  }
`

const Head = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;
`

const Date = styled(Typography)`
  display: block;
  @media ${mq.and(mobile)} {
    margin-top: 0;
  }
  color: ${props => props.theme.gray.d};
`

const Tags_Item = styled.div`
  margin-top: 8px;
  margin-left: 8px;
`

const Main = styled.div`
  margin-top: 12px;
  @media ${mq.and(mobile)} {
    margin-top: 8px;
  }
`

const Title = styled(Typography)`
  color: ${props => props.theme.gray.e};
`
