import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import Card from './Card1'

const Template = props => {
  const { posts, ...others } = props
  return (
    <Root {...others}>
      <List>
        <List_Container>
          {posts.map((post, index) => (
            <List_Item key={index}>
              <Card data={post} index={index} />
            </List_Item>
          ))}
        </List_Container>
      </List>
    </Root>
  )
}

const Root = styled.div`
`

const List = styled.div`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
`

const List_Container = styled.ul``

const List_Item = styled.li``

export default ThemeConsumer(Template)
